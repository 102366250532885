import React, { useState } from "react";
import "./App.css";
import FrontPage from "./components/Frontpage";
import ServicePage from "./components/servicePage/Services";
import Cursor from "./components/cursor/Cursor";

function App() {
  const [scaling, setScaling] = useState(false);
  return (
    <div className="App">
      <Cursor scaling={scaling}>
        <div id="home">
          <FrontPage setScaling={setScaling} />
        </div>
        <div id="services">
          <ServicePage setScaling={setScaling} />
        </div>
      </Cursor>
    </div>
  );
}

export default App;
