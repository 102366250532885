import React from "react";
import "../servicePage/Services.css";
import mobileApp from "../servicePage/images/mobileApp.png";
import group from "../servicePage/images/Group52.png";
import webAppImage from "../servicePage/images/WebApps.png";
import group2 from "../servicePage/images/Group2.png";
import cloudServiceImage from "../servicePage/images/cloudService.png";
import group55 from "../servicePage/images/Group55.png";
import group56 from "../servicePage/images/Group56.png";
import devops from "../servicePage/images/devops.png";
import analytics from "../servicePage/images/analytics.png";
import group58 from "../servicePage/images/Group58.png";
import map from "../servicePage/images/Group64.png";
import phoneIcon from "../servicePage/images/phoneIcon.png";
import mailIcon from "../servicePage/images/mailIcon.png";
import { Container } from "@mui/material";
import Navbar from "../Navbar";
import location from "../servicePage/images/location-icon.png";
import superQuick from "../servicePage/images/super-quick.png";
import grofto from "../servicePage/images/grofto.png";
import cloudThrifty from "../servicePage/images/cloud-thrifty.png";
import jomin from "../servicePage/images/jomin.png";
import demandHarvest from "../servicePage/images/demand-harvest.png";
import eclipse1 from "../servicePage/images/Ellipse1.png";
import eclipse2 from "../servicePage/images/Ellipse5.png";
import purple from "../servicePage/images/Ellipse8.png";
import darkGrey from "../servicePage/images/Ellipse14.png";
import footerPink from "../servicePage/images/Ellipse15.png";

function Services({ setScaling }) {
  const handleEmailClick = () => {
    const recipient = "Sooray@quasarinsightlabs.in";
    const subject = "Notification";
    const body = "The Reach image was clicked!";
    window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };
  return (
    <div className="main-container">
      <Navbar setScaling={setScaling} />

      <div className="service-text">Our Services</div>
      <div className="eclipse-image">
        <img src={eclipse1} alt="Eclipse" />
      </div>
      <Container className="custom-container">
        <div className="grid-container-1">
          <div className="mobileAppImg">
            <img src={mobileApp} alt="Mobile App" />
            <div className="shadowBox"></div>
          </div>

          <div className="text-section">
            <div className="heading">
              <p className="title">Mobile Apps</p>
            </div>
            <p>
              Transform your ideas into powerful, user-friendly mobile
              applications tailored for iOS and Android platforms.
            </p>
            <p>
              Whether you're an emerging tech startup or an established firm,
              TechEra provides everything you need to attract clients, show your
              success stories, and grow your business online.
            </p>
          </div>

          <div className="group-image">
            <img src={group} alt="Group-Image" />
          </div>
        </div>
      </Container>
      <Container className="custom-container">
        <div className="grid-container">
          <div className="group2-image">
            <img src={group2} alt="Group-Image" />
          </div>

          <div className="text-section-2">
            <div className="heading-2">
              <p className="title-2">Web Apps</p>
            </div>
            <p>
              Harness the full potential of web technologies with custom web
              applications that are scalable, secure, and intuitive.
            </p>
            <p>
              We understand the unique needs of the tech industry, which is why
              we’ve made it simple to highlight your services, showcase your
              projects, and share your knowledge through an integrated blog.
              With TechSite Builder, you don’t need to be a web developement.
            </p>
          </div>
          <div className="webAppImg">
            <div className="circle-shadow-image"></div>
            <img src={webAppImage} alt="Web App" />
          </div>
        </div>
      </Container>

      <Container className="custom-container">
        <div className="grid-container">
          <div className="cloudServiceImg">
            <div className="shadow-box-2"></div>
            <img src={cloudServiceImage} alt="Cloud Service" />
          </div>

          <div className="text-section-3">
            <div className="heading">
              <p className="title-3">Cloud services</p>
            </div>
            <p>
              Leverage the agility and scalability of cloud computing with our
              expertise in Google Cloud Platform (GCP) and Amazon Web Services
              (AWS).
            </p>
            <p>
              CloudWeb IT is a robust cloud-based service designed to empower IT
              companies and tech professionals with the ability to create and
              manage professional websites seamlessly.
            </p>
          </div>

          <div className="group-image-3">
            <img src={group55} alt="Group-Image" />
          </div>
        </div>
      </Container>
      <div className="eclipse-image-2">
        <img src={eclipse2} alt="Eclipse" />
      </div>
      <Container className="custom-container">
        <div className="grid-container">
          <div className="group4-image">
            <img src={group56} alt="Group-Image" />
          </div>

          <div className="text-section-4">
            <div className="heading-4">
              <p className="title-4">Devops</p>
            </div>
            <p>
              Streamline your development and operations lifecycle with our
              DevOps services.
            </p>
            <p>
              DevOpsWeb is a specialized DevOps service designed to streamline
              the process of creating, deploying, and managing websites for IT
              companies. Tailored for tech startups, development firms, and IT
              service providers, DevOpsWeb integrate
            </p>
          </div>
          <div className="devopsImg">
            <div className="shadow-box-4"></div>
            <img src={devops} alt="Devops" />
          </div>
        </div>
      </Container>
      <Container className="custom-container">
        <div className="grid-container">
          <div className="analyticsImg">
            <div className="shadow-box-5"></div>
            <img src={analytics} alt="Analytics" />
          </div>

          <div className="text-section-5">
            <div className="heading-5">
              <p className="title-5">Data Analytics</p>
            </div>
            <p>
              Gain actionable insights and drive strategic decisions with our
              advanced analytics services.
            </p>
            <p>
              InsightWeb Analytics is an advanced analytics service designed to
              empower IT companies with the data-driven insights they need to
              optimize and grow their websites. Tailored for tech firms,
              startups, and IT service providers, InsightWeb Analytics provides
              comprehensive tools to track, analyze, and enhance every aspect of
              your online presence.
            </p>
          </div>

          <div className="group-image-5">
            <img src={group58} alt="Group-Image" />
          </div>
        </div>
      </Container>
      <Container>
        <div className="trust">
          <p>Trusted By</p>
        </div>
      </Container>
      <Container>
        <div
          className="trusted-by"
          onMouseEnter={() => setScaling(true)}
          onMouseLeave={() => setScaling(false)}
        >
          <a
            href="https://superquick.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={superQuick} alt="Super Quick"></img>
          </a>
          <a href="#">
            <img src={cloudThrifty} alt="Cloud Thristy"></img>
          </a>
          <a
            href="https://grofto.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={grofto} alt="Grofto"></img>
          </a>
          <a
            href="https://demandharvest.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={demandHarvest} alt="Demand Harvest"></img>
          </a>
          <a href="#">
            <img src={jomin} alt="Jomin"></img>
          </a>
        </div>
      </Container>
      <div className="purple-image">
        <img src={purple} alt="Eclipse" />
      </div>
      <div className="mapImage">
        <Container>
          <img src={map} alt="Map" />{" "}
        </Container>
      </div>
      <div className="darkGrey-image">
        <img src={darkGrey} alt="Eclipse" />
      </div>
      <div className="footer">
        <div className="footer-image">
          <Container>
            <img src={footerPink} alt="Footer" className="footer-pink" />
          </Container>
        </div>
        <div className="footer-column">
          <p>Information</p>
          <ul>
            <li>Cookies</li>
          </ul>
        </div>
        <div
          className="footer-column"
          onMouseEnter={() => setScaling(true)}
          onMouseLeave={() => setScaling(false)}
        >
          <p>Useful Links</p>
          <ul>
            <a href="#services">
              {" "}
              <li>Our Services</li>{" "}
            </a>
            <a href="#contact-us" onClick={handleEmailClick}>
              {" "}
              <li>Contact Us</li>
            </a>
            <a
              href="https://linkedin.com/jobs/view/4026912400/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>Career</li>
            </a>
          </ul>
        </div>
        <div className="footer-column">
          <p>Social Link</p>

          <ul
            onMouseEnter={() => setScaling(true)}
            onMouseLeave={() => setScaling(false)}
          >
            <a
              href="https://linkedin.com/company/100507660"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <li>LinkedIn</li>
            </a>
          </ul>
        </div>
        <div className="footer-column">
          <p>Contact Us</p>
          <ul
            onMouseEnter={() => setScaling(true)}
            onMouseLeave={() => setScaling(false)}
          >
            <a href="tel:+917339559705">
              <li>
                <img src={phoneIcon} alt="Phone Icon" className="icon" />
                Phone: +91 7339559705
              </li>
            </a>
            <a href="mailto:soorya@quasarinsightlabs.in">
              <li>
                <img src={mailIcon} alt="Email Icon" className="icon" />
                Email: soorya@quasarinsightlabs.in
              </li>
            </a>
            <a
              href="https://maps.app.goo.gl/uaZbLNMdh3MgR4Nx5"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li>
                <img src={location} alt="Website Icon" className="icon" />
                53,2nd floor,nehru nagar
                <br />
                west kalapatti main road,
                <br />
                coimbatore-641014
              </li>
            </a>
          </ul>
        </div>
      </div>
      <Container>
        <div className="copy-rights">
          <p>
            Copyrights @ {new Date().getFullYear()} Quasar all rights reserved
          </p>
        </div>
      </Container>
    </div>
  );
}

export default Services;
